import Footer from "../globalComponents/footer"
import VideoBackground from "../home/videoBackground"
import { useState } from "react";
import axios from "axios";

function ContactUs() {

    const [error, setError] = useState("")
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    // console.log(formData)

    async function handleSubmit() {
        setError("")
        if (formData.name === '') {
            setError("no name")
            return
        }
        if (!validateEmail(formData.email)) {
            setError("invalid email")
            return
        }
        if (formData.subject === "") {
            setError("no subject")
            return
        }
        if (formData.message === "") {
            setError("no message")
            return
        }
        const sentEmail = await postEmail()
        if (sentEmail.message === "email sent") {
            setError("success")
        } else {
            setError("error sending email")
        }
        // console.log(sentEmail)
    }

    async function postEmail() {
        try {
            const response = await axios.post(
                `https://gmsapi1communication.com:443/webemail`,
                {
                    data: formData,
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error("Error fetching users sensors: " + err);
            setError("error sending email")
            throw err;
        }
    }

    return (
        <div className="w-full h-full bg-baseWhite">
            <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
                <VideoBackground
                    source={"powerline_clip.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div>
                        <h2 className="text-white text-[60px] leading-[60px] font-medium font-blender">Contact Us</h2>
                    </div>
                </div>
            </div>
            <div className="pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32">
                <h2 className="font-semibold text-xl laptop:text-4xl border-b-[3px] border-secondary pb-2 md:w-[35%] font-blender">Get in touch with us here</h2>
                <div className="bg-white mt-8 p-6 rounded-md shadow-md w-full lg:w-[650px]">
                    <div className="flex flex-col">
                        {error === "no name" && (
                            <label className=" text-md font-semibold text-red-600 mb-1 -mt-2">name field required</label>
                        )}
                        <input
                            name="name"
                            onChange={handleInput}
                            maxLength={1000}
                            placeholder="Name"
                            className="rounded-sm mb-5 pl-2 pr-2 w-full h-12 bg-white border-[1px] border-secondary"
                        />
                        {error === "invalid email" && (
                            <label className="text-md font-semibold text-red-600 mb-1 -mt-2">invalid email address</label>
                        )}
                        <input
                            name="email"
                            onChange={handleInput}
                            maxLength={200}
                            placeholder="Email"
                            className="rounded-sm mb-5 pl-2 pr-2 w-full h-12 bg-white border-[1px] border-secondary"
                        />
                        {error === "no subject" && (
                            <label className="text-md font-semibold text-red-600 mb-1 -mt-2">subject required</label>
                        )}
                        <input
                            name="subject"
                            onChange={handleInput}
                            maxLength={200}
                            placeholder="Subject"
                            className="rounded-sm mb-5 pl-2 pr-2 w-full h-12 bg-white border-[1px] border-secondary"
                        />
                        {error === "no message" && (
                            <label className="text-md font-semibold text-red-600 mb-1 -mt-2">message required</label>
                        )}
                        <textarea
                            name="message"
                            onChange={handleInput}
                            maxLength={2000}
                            placeholder="Message"
                            className="rounded-sm pl-2 pr-2 w-full min-h-36 max-h-36 bg-white pt-[10px] border-[1px] border-secondary"
                        />
                    </div>
                    {error !== "success" && error !== "error sending email" && (
                        <button onClick={() => handleSubmit()} className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-5">
                            <h2 className="text-white text-lg font-blender font-semibold ">Submit</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                    )}
                    {error === "success" && (
                        <div className="flex items-center mt-5">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-green-600">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>
                            <h2 className="text-green-600 text-lg font-semibold ml-2">Email has successfully been sent! We'll get back with you soon.</h2>
                        </div>
                    )}
                    {error === "error sending email" && (
                        <div className="flex items-center mt-5">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-red-600">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                            </svg>
                            <h2 className="text-red-600 text-lg font-semibold ml-2">There was an error sending your email. Please refresh the page and try again.</h2>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs