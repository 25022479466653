import { Link } from "react-router-dom"
import { useState, useEffect } from "react"

function Header({
    headerBg,
    animationType
}) {

    const [extendHamburger, setExtendHamburger] = useState(false)
    const [extendSolutions, setExtendSolutions] = useState(false)

    const backgrounds = {
        "top": "bg-white bg-opacity-[100%] headerBreak:bg-transparent shadow-none",
        "up": "bg-white bg-opacity-[100%]"
    }

    const textColors = {
        "top": "text-black headerBreak:text-white",
        "up": "text-black"
    }

    const buttonColors = {
        "top": "text-black headerBreak:text-white border-white hover:bg-baseWhite hover:bg-opacity-15 duration-100 ease-in-out",
        "up": "text-black border-baseGray hover:bg-baseGray hover:bg-opacity-10 duration-100 ease-in-out"
    }

    useEffect(() => {
        if (animationType !== "down") {
            setExtendHamburger(false)
        }
    }, [animationType])


    return (
        <>
            <div>
                {animationType === "down" && (
                    <div className={`bg-white bg-opacity-100 ${extendHamburger ? "translate-y-[-1px] shadow-lg" : "translate-y-[-400px]"} duration-200 ease-in-out z-[8888] h-56 w-full fixed top-20 pl-6 pr-6 md:pl-10 md:pr-10 flex flex-col items-end`}>
                        <Link onClick={() => {setTimeout(() => {setExtendHamburger(false)}, 100)}} to="/intellicharge" className="flex items-center">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender`}>IntelliCharge</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-5 ml-1 duration-100 transform text-black`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </Link>
                        
                        <Link onClick={() => {setTimeout(() => {setExtendHamburger(false)}, 100)}} to="/midaas" className="mt-2 flex items-center">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender`}>MIDAAS</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-5 ml-1 duration-100 transform text-black`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </Link>
                        <Link onClick={() => {setTimeout(() => {setExtendHamburger(false)}, 100)}} to="/securev" className="mt-2 flex items-center">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender`}>HydroFlex</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-5 ml-1 duration-100 transform text-black`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </Link>
                        <Link onClick={() => {setTimeout(() => {setExtendHamburger(false)}, 100)}} to="/aboutus" className="mt-6">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender ml-6`}>About Us</h2>
                        </Link>
                        {/* <Link onClick={() => {setTimeout(() => {setExtendHamburger(false)}, 100)}} to="/recognition" className="mt-2">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender ml-6`}>Recognition</h2>
                        </Link> */}
                        <Link onClick={() => {setTimeout(() => {setExtendHamburger(false)}, 100)}} to="/careers" className="mt-2">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender ml-6`}>Careers</h2>
                        </Link>
                        <Link onClick={() => {setTimeout(() => {setExtendHamburger(false)}, 100)}} to="/contact" className="mt-2">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender ml-6`}>Contact</h2>
                        </Link>
                        {/* <button>
                            <h2 className={`${textColors[headerBg]} text-xl font-blender mt-2`}>SecurEV</h2>
                        </button> */}
                    </div>
                )}
                <div className={`${backgrounds[headerBg]} w-full h-20 ${extendHamburger ? "" : "shadow-lg"} z-[9999] duration-150 ease-in-out transform fixed top-0 flex items-center justify-between pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42`}>
                    <Link to="/">
                        <button className="flex items-center">
                            <img src="/Grid_logo_mark.png" className="sm:w-[60px] sm:h-[60px] w-[48px] h-[48px]" />
                            <h2 className={`${textColors[headerBg]} text-start leading-6 text-[26px] ml-2 font-blender`}>Grid<br />Elevated</h2>
                        </button>
                    </Link>
                    <div className="items-center hidden headerBreak:flex">
                        <div className="relative">
                            <button
                                className="flex items-center"
                                onMouseEnter={() => setExtendSolutions(true)}
                                onMouseLeave={() => setExtendSolutions(false)}
                            >
                                <h2 className={`${textColors[headerBg]} text-xl font-blender text-black`}>Solutions</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${textColors[headerBg]} size-5 ml-1`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                            </button>
                            <div
                                className={`absolute bg-white shadow-xl rounded-md mt-2 p-3 -ml-3 duration-300 ease-in-out overflow-hidden transition-height ${extendSolutions ? 'max-h-36 opacity-100' : 'max-h-0 opacity-0'}`}
                                onMouseEnter={() => setExtendSolutions(true)}
                                onMouseLeave={() => setExtendSolutions(false)}
                            >
                                <Link to="/intellicharge">
                                    <h2 className={`text-black text-xl font-blender mt-2`}>IntelliCharge</h2>
                                </Link>
                                <Link to="/midaas">
                                    <h2 className={`text-black text-xl font-blender mt-2`}>MIDAAS</h2>
                                </Link>
                                <Link to="/hydroflex">
                                    <h2 className={`text-black text-xl font-blender mt-2`}>HydroFlex</h2>
                                </Link>
                            </div>
                        </div>
                        <Link to="/aboutus">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender ml-6`}>About Us</h2>
                        </Link>
                        {/* <Link to="/recognition">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender ml-6`}>Recognition</h2>
                        </Link> */}
                        <Link to="/careers">
                            <h2 className={`${textColors[headerBg]} text-xl font-blender ml-6`}>Careers</h2>
                        </Link>
                        <Link to="/contact">
                            <h2 className={`${buttonColors[headerBg]} text-xl text-baseGray rounded-lg font-blender ml-6 pl-3 pr-3 p-1 border-[2px]`}>Contact Us</h2>
                        </Link>
                    </div>
                    <div className="flex items-center headerBreak:hidden justify-end">
                        <button onClick={() => setExtendHamburger(!extendHamburger)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Header