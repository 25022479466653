import Footer from "../globalComponents/footer"
import VideoBackground from "../home/videoBackground"

function HydroFlex({

}) {
    return (
        <div className="w-full h-full">
            <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
                <VideoBackground
                    source={"MIDAASVid_1920_1080_24fps.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div>
                        <h2 className="text-white text-4xl leading-[34px] mobile:text-[60px] mobile:leading-[60px] w-full lg:w-[70%] xl:w-[53%] font-medium font-blender">Optimize your hydropower operations</h2>
                        <h3 className="text-md mobile:text-xl font-regular mt-2 w-full lg:w-[70%] xl:w-[53%] text-white font-ralewayReg">HydroFlex is a platform designed to co-optimize water and power systems to maximize economic and environmental benefits.</h3>
                        <div className="sm:flex mt-4">
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="w-full laptop:min-h-[100vh] bg-baseGray bg-top px-6 md:px-10 laptop:px-16 large-laptop:px-24 desktop:px-42 py-8 md:py-16 laptop:py-24 large-laptop:py-32 desktop:py-32 flex flex-col items-center justify-center ">
                <div className="w-full mt-0 flex items-center justify-center">
                    <img src="/images/securev2_white.png" className="hidden mobile:block w-[100%] laptop:w-[90%] md:w-[80%]"></img>
                </div>
            </div> */}
            <div className="w-full laptop:min-h-[100vh] bg-baseGray bg-top px-6 md:px-10 laptop:px-16 large-laptop:px-24 desktop:px-42 py-8 md:py-16 laptop:py-24 large-laptop:py-32 desktop:py-32  ">
                <div className="w-full grid grid-cols-12 gap-6">
                    <div className="col-span-12 flex justify-center">
                    <h2 className="z-40 text-xl laptop:text-2xl text-center text-textWhite font-ralewayReg w-full lg:w-[70%] large-laptop:w-[53%] desktop:w-[40%]">
                    HydroFlex leverages hydropower units to meet electricity generation goals.
                </h2>
                    </div>
                    <div className="col-span-12 px-64 py-12 rounded-2xl ">
                        <img src="/images/hydroflex_diagram.png" className=" hidden mobile:block w-[100%]"></img>
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-3 shadow-lg p-5 rounded-2xl bg-[#545870] bg-opacity-90">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-textWhite">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                        </svg>
                        <p className="text-xl font-ralewayReg mt-4 text-textWhite">Access the optimization from a web dashboard, API, or Excel Macro.</p>
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-3 shadow-lg p-5 rounded-2xl bg-[#545870] bg-opacity-90">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-textWhite">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                        </svg>
                        <p className="text-xl font-ralewayReg mt-4 text-textWhite">Award winning AI-based algorithm achieves optimal performance.</p>
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-3 shadow-lg p-5 rounded-2xl bg-[#545870] bg-opacity-90">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-textWhite">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
                        </svg>
                        <p className="text-xl font-ralewayReg mt-4 text-textWhite">Automated data acquisition streamlines model input requirements. </p>
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-3 shadow-lg p-5 rounded-2xl bg-[#545870] bg-opacity-90">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-textWhite">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                        </svg>
                        <p className="text-xl font-ralewayReg mt-4 text-textWhite">Customize to match any water or power system infrastructure.</p>
                    </div>
                </div>
            </div>
            {/* <div className="w-full laptop:min-h-[100vh] bg-baseGray bg-top px-6 md:px-10 laptop:px-16 large-laptop:px-24 desktop:px-42 py-8 md:py-16 laptop:py-24 large-laptop:py-32 desktop:py-32 flex flex-col items-center justify-center">
                <h2 className="z-40 text-xl laptop:text-2xl text-center text-textWhite font-ralewayReg w-full lg:w-[70%] large-laptop:w-[53%] desktop:w-[40%]">
                SecurEV is a hardware and software charging solution designed to provide security and reliability at every junction of charger-to-cloud communications.
                </h2>
                <div className="w-full flex items-center justify-center large-laptop:px-32 desktop:pr-48 desktop:pl-48">
                    <img src="/images/securev2_white.png" className="mt-12 laptop:mt-12 hidden mobile:block w-[100%] laptop:w-[90%] md:w-[80%]"></img>
                </div>
            </div> */}
            {/* <div className="w-full h-[100vh] bg-baseGray bg-top pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32  ">
                <h2 className="text-4xl text-textWhite font-ralewayReg">SecurEV is a hardware and software charging solution designed to provide security and reliability at every junction of charger-to-cloud communications.</h2>
                <div className="w-full mt-36 flex items-center justify-center">
                    <img src="/images/securev2_white.png" className="w-[100%] sm:w-[90%] md:w-[80%]"></img>
                </div>
            </div> */}
            <Footer />
        </div>
    )
}

export default HydroFlex