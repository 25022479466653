import { useState, useEffect, useRef } from "react"
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import '@splidejs/splide/dist/css/splide.min.css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import axios from "axios";

import Footer from "../globalComponents/footer"
import VideoBackground from "../home/videoBackground"

function AboutUs() {

    const [splideOptions, setSplideOptions] = useState({
        type: 'loop', // Ensure loop type
        width: '100%',
        height: 230,
        drag: 'free',
        gap: "1rem",
        perPage: 5,
        perMove: 1,
        pagination: false,
        autoScroll: {
            speed: 1.2,
            pauseOnHover: false, // Prevent pause on hover
            pauseOnFocus: false, // Prevent pause on focus 
        },
    });

    const splideRef = useRef()
    const splideParentDiv = useRef(null);
    const widthRef = useRef(null);
    const perPageRef = useRef(null);

    const goNext = () => splideRef.current.splide.go("+");
    const goPrev = () => splideRef.current.splide.go("-");

    const logoUrls = ['1200px-NSF_logo.png', 'DOEStamp.png', 'pacificorp-removebg-preview.png', 'heberLightAndPowerLogo.png', 'utahCleanCitiesLogo.png', 'leadersForCleanAirLogo.png', 'u_of_u.png', 'usmart_logo.png', 'UCAIR_Logo.png']

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (window.innerWidth >= 2400) {
    //             setSplideOptions(options => ({ ...options, perPage: 9 }));
    //         } else if (window.innerWidth >= 2000) {
    //             setSplideOptions(options => ({ ...options, perPage: 8 }));
    //         } else if (window.innerWidth >= 1600) {
    //             setSplideOptions(options => ({ ...options, perPage: 7 }));
    //         } else if (window.innerWidth >= 1400) {
    //             setSplideOptions(options => ({ ...options, perPage: 6 }));
    //         } else if (window.innerWidth >= 1200) {
    //             setSplideOptions(options => ({ ...options, perPage: 5 }));
    //         } else if (window.innerWidth >= 1000) {
    //             setSplideOptions(options => ({ ...options, perPage: 4 }));
    //         } else if (window.innerWidth >= 800) {
    //             setSplideOptions(options => ({ ...options, perPage: 3 }));
    //         } else {
    //             setSplideOptions(options => ({ ...options, perPage: 2 }));
    //         }
    //     }, 300)
    // }, [])

    useEffect(() => {
        const handleResize = () => {
            if (splideParentDiv.current) {
                const newWidth = splideParentDiv.current.offsetWidth;
                let newPerPage = 2;

                if (newWidth >= 2200) {
                    newPerPage = 9;
                } else if (newWidth >= 1800) {
                    newPerPage = 8;
                } else if (newWidth >= 1400) {
                    newPerPage = 7;
                } else if (newWidth >= 1200) {
                    newPerPage = 6;
                } else if (newWidth >= 1000) {
                    newPerPage = 5;
                } else if (newWidth >= 800) {
                    newPerPage = 4;
                } else if (newWidth >= 600) {
                    newPerPage = 3;
                }

                if (widthRef.current !== newWidth || perPageRef.current !== newPerPage) {
                    widthRef.current = newWidth;
                    perPageRef.current = newPerPage;
                    setSplideOptions(options => ({
                        ...options,
                        // width: newWidth,
                        perPage: newPerPage,
                    }));
                }
            }
        };

        if (splideParentDiv.current) {
            handleResize();
        }

        const resizeObserver = new ResizeObserver(handleResize);
        if (splideParentDiv.current) {
            resizeObserver.observe(splideParentDiv.current);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            if (splideParentDiv.current) {
                resizeObserver.unobserve(splideParentDiv.current);
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [splideParentDiv]);

    const [error, setError] = useState("")
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    // console.log(formData)

    async function handleSubmit() {
        setError("")
        if (formData.name === '') {
            setError("no name")
            return
        }
        if (!validateEmail(formData.email)) {
            setError("invalid email")
            return
        }
        if (formData.subject === "") {
            setError("no subject")
            return
        }
        if (formData.message === "") {
            setError("no message")
            return
        }
        const sentEmail = await postEmail()
        if (sentEmail.message === "email sent") {
            setError("success")
        } else {
            setError("error sending email")
        }
        // console.log(sentEmail)
    }

    async function postEmail() {
        try {
            const response = await axios.post(
                `https://gmsapi1communication.com:443/webemail`,
                {
                    data: formData,
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error("Error fetching users sensors: " + err);
            setError("error sending email")
            throw err;
        }
    }

    return (
        <div className="w-full h-full bg-baseWhite">
            <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
                <VideoBackground
                    source={"powerline_clip.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div className="">
                        <h2 className="text-white text-[60px] leading-[60px] font-medium font-blender">About Us</h2>
                    </div>
                </div>
            </div>
            <div className="bg-baseWhite pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32">
                <p className="text- text-xl laptop:text-2xl font-ralewayReg">Grid Elevated solves the juxtaposition between the last-generation grid and the next generation of needs, by creating AI-powered solutions that cater the intelligence, resilience and security needs for grid transformation. A proud Utah-based company made up of world-renowned researchers, innovators, energy experts, and advocates, Grid Elevated creates the technological solutions to make today’s power grid more intelligent, resilient, and secure for tomorrow.</p>
                <h3 className="text- text-4xl font-semibold mt-12 border-b-[3px] pb-1 border-secondary md:w-[35%] font-blender">Our Mission</h3>
                <p className="w-full mt-2  text-lg font-ralewayReg" >Grid Elevated's mission is to create innovative technology solutions that help communities, industries, and utilities to integrate clean, affordable, and resilient electricity services. We rely on our established research prowess and combine it with our vigor to commercialize our cutting-edge technologies to produce innovative and game-changing products.</p>
                {/* <h3 className="text- text-4xl font-semibold mt-12 border-b-[3px] pb-1 border-secondary md:w-[35%] font-blender">Our Team</h3> */}
                {/* <div className="w-full grid grid-cols-10 gap-6 mt-3">
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-md flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-center object-cover rounded-tl-md rounded-bl-md" src="/images/Masood.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Masood Parvania</h2>
                            <h3 className="p-2 pt-2 text-sm ">Founder and President</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/Jairo.png" alt="Jairo Giraldo"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Jairo Giraldo</h2>
                            <h3 className="p-2 pt-2 text-sm ">Manager, Product Development</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/Alex.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Alex Farley</h2>
                            <h3 className="p-2 pt-2 text-sm ">Lead Software Developer</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/Carter.jpg" alt="Carter Johnson"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Carter Johnson</h2>
                            <h3 className="p-2 pt-2 text-sm ">Front-End Software Developer</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/Adrian.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Adrian Sucahyo</h2>
                            <h3 className="p-2 pt-2 text-sm ">Embedded Systems Engineer</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/drew.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Drew Humphrey</h2>
                            <h3 className="p-2 pt-2 text-sm ">Software Development Intern</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/CynthiaYeo.png.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Cynthia Yeo</h2>
                            <h3 className="p-2 pt-2 text-sm ">Marketing and Content Architect</h3>
                        </div>
                    </div>
                    <div className="col-span-10 mobile:col-span-5 lg:col-span-3 xl:col-span-2 h-48 rounded-md bg-white shadow-lg flex ">
                        <div className="w-[60%] h-full">
                            <img className="w-full h-full object-cover rounded-bl-md rounded-tl-md" src="/images/AnnaCopeland.png.jpg"></img>
                        </div>
                        <div className=" w-[40%]">
                            <h2 className="font-semibold p-2 pb-0 text-lg leading-6 text-">Anna Copeland-Rynders</h2>
                            <h3 className="p-2 pt-2 text-sm ">Graphic Designer</h3>
                        </div>
                    </div>
                </div> */}
                <h3 className="text- text-4xl font-semibold mt-12 border-b-[3px] pb-1 border-secondary md:w-[35%] font-blender">Awards and Recognition</h3>
                <div className="w-full grid grid-cols-3 gap-8 pt-8 md:pt-10 laptop:pt-12 large-laptop:pt-16 desktop:pt-16 pb-6 md:pb-8 laptop:pb-8 large-laptop:pb-10 desktop:pb-12 ">
                    <div className="col-span-3 sm:col-span-2 flex flex-col justify-center">
                        <h2 className="font-semibold text-3xl border-b-[3px] border-secondary pb-2 font-blender">DOE Digitizing Utilities Round 2 Phase II Awardee</h2>
                        <p className="mt-4 text-lg font-ralewayReg">Grid Elevated was selected as one of 3 winners of the DOE Office of Electricity Digitizing Utilities Prize Round 2. Grid Elevated was awarded for its developments on the MIDAAS platform, showing its value to utility partner Heber Light and Power in a case study and pilot deployment. The MIDAAS analytic tools provided significant benefits including substantial operational cost reductions.</p>
                        <a href="https://www.energy.gov/oe/articles/doe-selects-2nd-round-winners-launches-3rd-round-digitizing-utilities-prize" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-4 w-[136px]">
                            <h2 className="font-blender text-white text-lg">Learn More </h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </div>
                    <div className="hidden sm:block col-span-1 flex items-center">
                        <div className="">
                            <img className="max-h-96" src="/images/digitizing_prize_2.jpeg"></img>
                        </div>
                    </div>
                </div>
                <div className="w-full grid grid-cols-3 gap-8 pt-8 md:pt-10 laptop:pt-12 large-laptop:pt-16 desktop:pt-16 pb-6 md:pb-8 laptop:pb-8 large-laptop:pb-10 desktop:pb-12 ">
                    <div className="col-span-3 sm:col-span-2 flex flex-col justify-center">
                        <h2 className="font-semibold text-3xl border-b-[3px] border-secondary pb-2 font-blender">DOE Digitizing Utilities Round 2 Phase I Awardee</h2>
                        <p className="mt-4 text-lg font-ralewayReg">Grid Elevated was selected as one of 9 winners of the DOE Office of Electricity Digitizing Utilities Prize Round 2. Grid Elevated was selected to develop the Multi-Source Data Acquisition, Analytics, Sharing, and Security (MIDAAS) Platform, a toolkit of secure data analytic modules built upon Grid Elevated’s data acquisition solution with Heber Light & Power. The MIDAAS Platform provides the infrastructure to translate large amounts of data recently enabled by the energy transition to meaningful information to impact decision-making.</p>
                        <a href="https://www.herox.com/DigitizingUtilitiesRound2/update/6954" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-4 w-[136px]">
                            <h2 className="font-blender text-white text-lg">Learn More </h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </div>
                    <div className="hidden sm:block col-span-1 flex items-center">
                        <div className="">
                            <img className="max-h-96" src="/images/digitizingAward.jpeg"></img>
                        </div>
                    </div>
                </div>
                <div className="w-full grid grid-cols-3 gap-8 pt-8 md:pt-10 laptop:pt-12 large-laptop:pt-16 desktop:pt-16 pb-6 md:pb-8 laptop:pb-8 large-laptop:pb-10 desktop:pb-12 ">
                    <div className="col-span-3 sm:col-span-2 flex flex-col justify-center">
                        <h2 className="font-semibold text-3xl border-b-[3px] border-secondary pb-2 font-blender">DOE WPTO H2Os Grand Prize Winner</h2>
                        <p className="mt-4 text-lg font-ralewayReg">A team of engineers from Grid Elevated and the Utah Smart Energy Lab at the University of Utah won all three phases of the DOE Water Power Technologies Office Hydropower Operations Optimization (H2Os). The competition required teams to develop optimization models to optimize water systems and hydropower generation while considering power system constraints. The team developed HydroFlex, a cloud-based software designed to solve the hydropower generation problem. See a demonstration of the platform.</p>
                        <a href="https://www.energy.gov/eere/articles/doe-announces-winners-hydropower-operations-optimization-prize" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-4 w-[136px]">
                            <h2 className="font-blender text-white text-lg">Learn More </h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </div>
                    <div className="hidden sm:block col-span-1 flex items-center">
                        <div className="">
                            <img className="max-h-96" src="/images/hydroflexAward.png"></img>
                        </div>
                    </div>
                </div>
                <div className="w-full grid grid-cols-3 gap-8 pt-8 md:pt-10 laptop:pt-12 large-laptop:pt-16 desktop:pt-16 pb-6 md:pb-8 laptop:pb-8 large-laptop:pb-10 desktop:pb-12 ">
                    <div className="col-span-3 sm:col-span-2 flex flex-col justify-center">
                        <h2 className="font-semibold text-3xl border-b-[3px] border-secondary pb-2 font-blender">2024 Utah Business Innovation Award</h2>
                        <p className="mt-4 text-lg font-ralewayReg">A team of engineers from Grid Elevated and the Utah Smart Energy Lab at the University of Utah won all three phases of the DOE Water Power Technologies Office Hydropower Operations Optimization (H2Os). The competition required teams to develop optimization models to optimize water systems and hydropower generation while considering power system constraints. The team developed HydroFlex, a cloud-based software designed to solve the hydropower generation problem. See a demonstration of the platform.</p>
                        <a href="https://www.utahbusiness.com/2024-innovation-awards/" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-4 w-[136px]">
                            <h2 className="font-blender text-white text-lg">Learn More </h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </div>
                    <div className="hidden sm:block col-span-1 flex items-center">
                        <div className="">
                            <img className="max-h-96" src="/images/GroupPhoto.jpeg"></img>
                        </div>
                    </div>
                </div>
                <div className="pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32">
                    <h2 className="font-semibold text-4xl border-b-[3px] border-secondary pb-2 md:w-[35%] font-blender">Get in touch with us here</h2>
                    <div className="bg-white mt-8 p-6 rounded-md shadow-md w-full lg:w-[650px]">
                        <div className="flex flex-col">
                            {error === "no name" && (
                                <label className=" text-md font-semibold text-red-600 mb-1 -mt-2">name field required</label>
                            )}
                            <input
                                name="name"
                                onChange={handleInput}
                                maxLength={1000}
                                placeholder="Name"
                                className="rounded-sm mb-5 pl-2 pr-2 w-full h-12 bg-white border-[1px] border-secondary"
                            />
                            {error === "invalid email" && (
                                <label className="text-md font-semibold text-red-600 mb-1 -mt-2">invalid email address</label>
                            )}
                            <input
                                name="email"
                                onChange={handleInput}
                                maxLength={200}
                                placeholder="Email"
                                className="rounded-sm mb-5 pl-2 pr-2 w-full h-12 bg-white border-[1px] border-secondary"
                            />
                            {error === "no subject" && (
                                <label className="text-md font-semibold text-red-600 mb-1 -mt-2">subject required</label>
                            )}
                            <input
                                name="subject"
                                onChange={handleInput}
                                maxLength={200}
                                placeholder="Subject"
                                className="rounded-sm mb-5 pl-2 pr-2 w-full h-12 bg-white border-[1px] border-secondary"
                            />
                            {error === "no message" && (
                                <label className="text-md font-semibold text-red-600 mb-1 -mt-2">message required</label>
                            )}
                            <textarea
                                name="message"
                                onChange={handleInput}
                                maxLength={2000}
                                placeholder="Message"
                                className="rounded-sm pl-2 pr-2 w-full min-h-36 max-h-36 bg-white pt-[10px] border-[1px] border-secondary"
                            />
                        </div>
                        {error !== "success" && error !== "error sending email" && (
                            <button onClick={() => handleSubmit()} className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-5">
                                <h2 className="text-white text-lg font-blender font-semibold ">Submit</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                </svg>
                            </button>
                        )}
                        {error === "success" && (
                            <div className="flex items-center mt-5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-green-600">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                </svg>
                                <h2 className="text-green-600 text-lg font-semibold ml-2">Email has successfully been sent! We'll get back with you soon.</h2>
                            </div>
                        )}
                        {error === "error sending email" && (
                            <div className="flex items-center mt-5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-red-600">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                                </svg>
                                <h2 className="text-red-600 text-lg font-semibold ml-2">There was an error sending your email. Please refresh the page and try again.</h2>
                            </div>
                        )}
                    </div>
                </div>
                <h3 className="text-black text-4xl font-semibold mt-12 border-b-[3px] pb-1 border-secondary md:w-[35%] font-blender">Our Partners</h3>
                <div ref={splideParentDiv} className="-ml-24 -mr-24 h-56 ">
                    <Splide
                        ref={splideRef}
                        options={splideOptions}
                        hasTrack={false}
                        extensions={{ AutoScroll }}
                    >
                        <SplideTrack>
                            {logoUrls.map((logo, index) => (
                                <SplideSlide key={index}>
                                    <div className="w-full h-full flex items-center justify-center p-6 laptop:p-8 mr-6 ">
                                        <img src={`/images/${logo}`}></img>
                                    </div>
                                </SplideSlide>
                            ))}
                        </SplideTrack>
                        <div className="splide__arrows hidden"></div>
                    </Splide>
                </div>
                <div className="w-full grid grid-cols-12 gap-6 mt-24">
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-4 aspect-w-4 aspect-h-3 overflow-hidden p-2 flex items-center bg-white rounded-lg shadow-lg">
                        <img
                            alt="Large group photo of the Grid Elevated team and their sponsors."
                            src="/images/GroupPhoto.jpeg"
                            className="object-cover w-full"
                        />
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-4 aspect-w-4 aspect-h-3 overflow-hidden p-2 flex items-center bg-white rounded-lg shadow-lg">
                        <img
                            alt="Group photo of some members of the Grid Elevated team in front of a Tesla."
                            src="/images/GrandAmericaGroupPicTesla.jpg"
                            className="object-cover w-full"
                        />
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-4 aspect-w-4 aspect-h-3 overflow-hidden p-2 flex items-center bg-white rounded-lg shadow-lg">
                        <img
                            alt="Group photo of some members of the Grid Elevated team in front of a Tesla."
                            src="/images/GridElevatedGroupPhotoStand.jpeg"
                            className="object-cover w-full"
                        />
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default AboutUs